<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>

      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          LIST GROUP
        </h4>

      </div>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>groups</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEventTable"
        :items="fetchEvent"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="fetchEvent.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- Column: totalUsers -->
        <template #cell(totalUsers)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ item.totalUsers }}
            </div>
            <b-button
              variant="primary"
              class="ml-2"
              style="padding: 4px; font-size: 12px; width: 40px;"
              @click="handleShow(item)"
            >
              View
            </b-button>
          </div>
        </template>
        <!-- Column: createdTime -->
        <template #cell(createdTime)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ formatTime(item.createdTime, 'HH:mm:ss dd-MM-yyyy') }}
            </div>
          </div>
        </template>
        <!-- Column: realBalance -->
        <template #cell(realBalance)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ formatCurrencyBuiltInFunc(item.realBalance) }}
            </div>
          </div>
        </template>
        <!-- Column: action -->
        <template #cell(action)="{ item }">
          <div
            class="d-flex align-items-center font-weight-bold id-column"
          >
            <b-dropdown
              variant="link"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="handleEdit(item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Change Profile</span>
              </b-dropdown-item>

            </b-dropdown>
          </div>
        </template>

        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="formatColorType(item.status)"
            >
              {{ item.status === 'lock' ? 'Closed' : 'Active' }}
            </b-badge>
          </b-media>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalGroup"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <!-- ADD NEW CONFIG -->

      <b-modal
        ref="config"
        title="Config Profile"
        centered
        hide-footer
        cancel-variant="outline-secondary"
        size="md"
        @hide="hideModal"
      >
        <section style="color: black">
          <div>

            <!-- profile -->
            <b-row class="mt-2">
              <b-col
                cols="5"
                class="d-flex align-items-center"
              >
                <div class="font-weight-bold">
                  Profile Type
                </div>
              </b-col>
              <b-col
                cols="6"
                class="d-flex"
              >
                <b-form-select
                  v-model="selectedProfile"
                  :options="clientProfileList"
                />
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loadingSetting"
                @click="handleSaveNewConfig"
              >
                <Spinner v-if="loadingSetting" />
                <template v-else>
                  Save
                </template>
              </b-button>
            </b-col>
          </b-row>
        </section>
      </b-modal>

      <!-- LIST USER GROUP -->
      <b-modal
        ref="list"
        title="List Member"
        centered
        hide-footer
        cancel-variant="outline-secondary"
        size="lg"
        @hide="hideModalMember"
      >
        <section style="color: black">

          <h4 class="mb-2">
            Group {{ groupId }}
          </h4>
          <b-table
            ref="refMemberTable"
            :items="fetchMemberOfGroup"
            :fields="tableColumnsMember"
            responsive
            show-empty
            primary-key="id"
          >
            <template #empty>
              <div
                v-if="fetchMemberOfGroup.length === 0"
                class="text-center my-2"
              >
                No matching records found
              </div>
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  Showing {{ dataMetaMember.from }} to {{ dataMetaMember.to }} of {{ dataMetaMember.of }} items
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageMember"
                  :total-rows="totalMember"
                  :per-page="perPageMember"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="hideModalMember"
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </section>
      </b-modal>

    </b-card></div>
</template>

<script>
import {
  BTable,
  BPagination,
  BRow,
  BCol,
  BCard,
  VBModal,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormSelect,
  BFormInput,
  BMedia,
  BBadge,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import {
  formatDate, formatDateTimeLocal, formatTime, formatDateSend, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'
import { debounce } from 'debounce'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormSelect,
    Spinner,
    BFormInput,
    vSelect,
    BMedia,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      selectedProfile: null,
      loadingSetting: false,
      selectedItem: null,
      groupId: null,
    }
  },
  computed: {
    ...mapState('races/listGroup', ['loadingListGroup']),
    ...mapState('races/setting', ['clientProfileList']),

  },
  watch: {
    selectedSport() {
      this.currentPage = 1
      this.refetchData()
    },
  },
  async mounted() {
    await this.getProfileType()
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      setNewSettingUpdateProfileBetBack: 'setting/setting/setNewSettingUpdateProfileBetBack',
      getListGroup: 'races/listGroup/getListGroup',
      updateGroupProfile: 'races/listGroup/updateGroupProfile',
      getProfileType: 'races/setting/getProfileType',
      getListMemberOfGroup: 'races/listGroup/getListMemberOfGroup',
    }),
    formatColorType(val) {
      if (val === 'lock') return 'light-warning'
      return 'light-success'
    },
    async fetchEvent() {
      const data = await this.getListGroup({
        limit: this.perPage,
        page: this.currentPage,
        type: 'punter',
        searchParams: this.searchQuery,
      })
      if (!data && data.data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalGroup = data.pagination.total
      return data.data
    },
    async fetchMemberOfGroup() {
      if (!this.groupId) return []
      const data = await this.getListMemberOfGroup(this.groupId)
      if (!data && data.data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalMember = data.pagination.total
      return data.data
    },
    hideModal() {
      this.selectedProfile = null
      this.limitEstReturn = null
      this.selectedAutoAccept = false
      this.selectedPlaceBet = false

      this.isEdit = false
    },
    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleSaveNewConfig(event) {
      event.preventDefault()
      this.loadingSetting = true

      const res = await this.updateGroupProfile({
        groupId: this.selectedItem.groupId,
        profile: this.selectedProfile,
      })
      if (res) {
        this.refetchData()
        this.$refs.config.hide()
        this.showToastSuccess('Success', 'change profile of the group successfully')
      }
      this.loadingSetting = false
    },
    handleEdit(item) {
      this.selectedProfile = item.profile
      this.selectedItem = item
      this.$refs.config.show()
    },
    hideModalMember() {
      this.$refs.list.hide()
      this.groupId = null
    },
    handleShow(item) {
      this.groupId = item.groupId
      this.$refs.list.show()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    // TABLE LIST GROUP
    const refEventTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'groupId', label: 'COMP RACE EVENT ID' },
      { key: 'name' },
      { key: 'code' },
      { key: 'realBalance', label: 'balance' },
      { key: 'totalUsers', label: 'total users' },
      { key: 'createdTime' },
      { key: 'profile' },
      { key: 'status' },
      { key: 'action' },
    ]

    const perPage = ref(100)
    const totalGroup = ref(0)
    const totalRaces = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref(null)

    const refetchData = () => {
      refEventTable.value.refresh()
    }

    const dataMeta = computed(() => {
      const localItemsCount = refEventTable.value ? refEventTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalGroup.value,
      }
    })

    watch([currentPage, perPage], () => {
      refetchData()
    })
    watch(
      [searchQuery],
      debounce(() => {
        totalGroup.value = 0
        refetchData()
      // eslint-disable-next-line comma-dangle
      }, 700)
    )

    // TABLE LIST MEMBER
    const refMemberTable = ref(null)

    const tableColumnsMember = [
      { key: 'userId' },
      { key: 'username' },
      { key: 'roleName', label: 'role' },
    ]

    const perPageMember = ref(10)
    const totalMember = ref(0)
    const currentPageMember = ref(1)

    const refetchDataMember = () => {
      refMemberTable.value.refresh()
    }

    const dataMetaMember = computed(() => {
      const localItemsCount = refMemberTable.value ? refMemberTable.value.localItems.length : 0
      return {
        from: perPageMember.value * (currentPageMember.value - 1) + (localItemsCount ? 1 : 0),
        to: perPageMember.value * (currentPageMember.value - 1) + localItemsCount,
        of: totalMember.value,
      }
    })

    watch([currentPageMember, perPageMember], () => {
      refetchDataMember()
    })
    watch(
      [searchQuery],
      debounce(() => {
        totalGroup.value = 0
        refetchData()
      // eslint-disable-next-line comma-dangle
      }, 700)
    )

    return {
      perPage,
      totalGroup,
      dataMeta,

      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      totalRaces,
      refEventTable,
      refetchData,
      perPageOptions,

      formatTime,

      showToastSuccess,
      formatDateSend,
      searchQuery,
      formatCurrencyBuiltInFunc,

      refMemberTable,
      tableColumnsMember,
      dataMetaMember,
      perPageMember,
      totalMember,
      currentPageMember,

    }
  },
}
</script>

    <style lang="scss" scoped>
    .id-column {
      max-width: 150px;
      word-break: break-word;
    }
    .text-title {
      color: black;
      font-weight: 500;
    }
    .per-page-selector {
        width: 90px;
    }
    </style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
